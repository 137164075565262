// authMixin.js
import { BASE_URL, API_KEY } from '../api';

export default {
    methods: {
        async checkAuth() {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${BASE_URL}/api/v1/check_auth`, {
                    method: 'GET',
                    credentials: 'include', // Include cookies in the request
                    headers: {
                        'Content-Type': 'application/json',
                        'API-Key': API_KEY,
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    // User is not authenticated, redirect to login page
                    this.$router.push('/');
                }
            } catch (error) {
                console.error('Error:', error);
                // Redirect to login page on error for security reasons
                this.$router.push('/');
            }
        },
    },
    created() {
        this.checkAuth();
    },
};

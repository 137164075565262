const BASE_URL = `${window.location.protocol}//${window.location.hostname}`;
const API_KEY = '8wjhNATsHSXeWr9-_-fDHT6ghb3U8rv5cagBPhrwSIc=';

async function fetchWithAuth(url, options) {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'API-Key': API_KEY,
        Authorization: `Bearer ${token}`,
    };
    const requestOptions = {
        ...options,
        headers: {
            ...options.headers,
            ...headers,
        },
    };

    const response = await fetch(`${BASE_URL}/${url}`, requestOptions);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

export { BASE_URL, API_KEY, fetchWithAuth };

// router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import AboutApplication from '../components/AboutApplication.vue';
import HomePage from '../components/HomePage.vue';
import LoginPage from '../components/LoginPage.vue';
import LogoutFromApplication from '../components/LogoutFromApplication.vue';

const routes = [
    {
        path: '/',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/homepage',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/about',
        name: 'AboutApplication',
        component: AboutApplication,
    },
    {
        path: '/logout',
        name: 'LogoutFromApplication',
        component: LogoutFromApplication,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Navigation guard to check authentication status before navigating to protected routes
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('token') !== null; // Check if token exists in localStorage
    if (to.meta.requiresAuth && !isAuthenticated) {
        // If route requires authentication and user is not authenticated, redirect to login
        next('/'); // Redirect to the login page
    } else {
        // Otherwise, allow navigation
        next();
    }
});

export default router;

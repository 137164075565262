<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="login">
      <label for="username">Username:</label>
      <input
        id="username"
        v-model="username"
        type="text"
      >
      <label for="password">Password:</label>
      <input
        id="password"
        v-model="password"
        type="password"
      >
      <button type="submit">
        Login
      </button>
    </form>
    <p
      v-if="errorMessage"
      class="error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { BASE_URL, API_KEY } from '../api';
export default {
    data() {
        return {
            username: '',
            password: '',
            errorMessage: '',
        };
    },
    methods: {
        ...mapActions('auth', ['login']),
        async login() {
            try {
                const response = await fetch(`${BASE_URL}/api/v1/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'API-Key': API_KEY,
                    },
                    body: JSON.stringify({
                        username: this.username,
                        password: this.password,
                    }),
                });
                if (response.ok) {
                    const responseData = await response.json();
                    //console.log('Response data from server: ', responseData);
                    const token = responseData.token;
                    // Store token in localStorage or Vuex store for later use
                    localStorage.setItem('token', token);

                    // Set isAuthenticated to true in Vuex store
                    this.$store.commit('setAuthenticationStatus', true);

                    // Successful login, navigate to the dashboard or homepage
                    // You can use Vue Router for navigation
                    this.$router.push('/homepage');
                } else {
                    // Unsuccessful login, display error message
                    this.errorMessage = 'Invalid username or password';
                }
            } catch (error) {
                console.error('Error:', error);
                this.errorMessage = 'An error occurred while trying to login';
            }
        },
    },
};
</script>

<style scoped>
.error {
    color: red;
}
</style>

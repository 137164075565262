<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      This small application creates accounts on dbpress website based in
      the data downloaded from the SBI portal.
    </p>
  </div>
</template>

<script>
export default {
    name: 'AboutApplication',
    props: {
        msg: {
            type: String,
            default: 'Ra Dha Sva Aa Mi',
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>

<template>
  <!-- Timed row section -->
  <div v-if="accountsTimedRow">
    <p>Accounts Created: {{ accountsCreatedCount }}</p>
    <p>Accounts Failed: {{ accountsFailedCount }}</p>
    <p>Accounts in Conflict: {{ accountsConflictCount }}</p>
  </div>

  <div>
    <p>Please select a file to upload</p>
    <input
      type="file"
      accept=".csv"
      @change="handleFileUpload"
    >
    <button
      v-if="fileUploaded"
      @click="toggleEditMode"
    >
      {{ editMode ? 'Save' : 'Edit' }}
    </button>
    <button
      v-if="fileUploaded"
      @click="deleteRow"
    >
      Delete Record
    </button>
    <button
      v-if="fileUploaded"
      @click="createAccounts"
    >
      Create Accounts
    </button>
    <div v-if="parsedData.length > 0">
      <table class="custom-table">
        <thead>
          <tr>
            <th>
              <input
                v-model="selectAll"
                type="checkbox"
                :disabled="areAllCheckboxesDisabled()"
                @change="selectAllRows"
              >
            </th>
            <th
              v-for="header in headers"
              :key="header"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in parsedData"
            :key="`row-${rowIndex}`"
            :class="{
              'success-row': row.success,
              'error-row': row.error,
              'conflict-row': row.conflict,
            }"
          >
            <td>
              <input
                v-model="selectedRows"
                type="checkbox"
                :value="rowIndex"
                :disabled="!selectableRows[rowIndex]"
                @change="selectRow(rowIndex)"
              >
            </td>
            <td
              v-for="(header, colIndex) in headers"
              :key="`col-${colIndex}`"
            >
              <template v-if="!isEditable(rowIndex)">
                {{ row[header] }}
              </template>
              <template v-else>
                <input v-model="row[header]">
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import authMixin from '../mixins/authMixin';
import Papa from 'papaparse';
import { BASE_URL } from '../api';

export default {
    name: 'HomePage',
    mixins: [authMixin],
    data() {
        return {
            parsedData: [],
            headers: [],
            editMode: false,
            fileUploaded: false,
            selectAll: false,
            selectedRows: [],
            selectableRows: [],
            accountsTimedRow: false,
            accountsCreatedCount: 0,
            accountsFailedCount: 0,
            accountsConflictCount: 0,
            timedRowTimeout: null,
        };
    },
    computed: {
        // Computed property to determine if a row is editable
        isEditable() {
            return (rowIndex) => {
                return this.editMode && this.selectedRows.includes(rowIndex);
            };
        },
    },
    watch: {
        parsedData: {
            immediate: true, // Initialize immediately on component mount
            handler(newData) {
                if (newData && newData.length > 0) {
                    this.initializeSelectableRows();
                }
            },
        },
    },
    methods: {
        initializeSelectableRows() {
            //this.selectableRows = [...this.parsedData.map(() => true)]; // Convert to regular array
            // Or:
            this.selectableRows = this.parsedData.map(() => true).slice(); // Using slice method
        },
        areAllCheckboxesDisabled() {
            if (this.selectableRows.every((row) => !row)) {
                return true;
            } else {
                return false;
            }
        },
        selectAllRows() {
            // Disable the "Select All" checkbox in the header when all checkboxes are disabled
            if (this.areAllCheckboxesDisabled()) {
                this.selectAll = false;
                return;
            }
            this.selectedRows = this.selectAll
                ? this.selectableRows
                    .map((_, index) => index)
                    .filter((index) => this.selectableRows[index]) // Include only the indices for which checkbox is enabled
                : [];
        },
        selectRow(rowIndex) {
            if (!this.selectableRows[rowIndex]) {
                // If the row is not selectable, remove it from selectedRows
                this.selectedRows = this.selectedRows.filter(
                    (index) => index !== rowIndex,
                );
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                Papa.parse(file, {
                    delimiter: '~',
                    complete: (result) => {
                        this.transformData(result.data.slice(3)); // Skip the first 3 rows
                        this.fileUploaded = true; // Set fileUploaded to true after file upload
                    },
                });
            }
        },
        deleteRow() {
            // Iterate through the selectedRows array in reverse order
            for (let i = this.selectedRows.length - 1; i >= 0; i--) {
                const rowIndex = this.selectedRows[i];
                this.parsedData.splice(rowIndex, 1); // Remove the row from parsedData
                this.selectableRows.splice(rowIndex, 1); // Remove the corresponding item from selectableRows
            }
            // Clear the selectedRows array
            this.selectedRows = [];
        },
        transformData(rows) {
            const dataWithHeaders = [];
            // Initialize headers for the first 6 fields
            this.headers = [
                'Subscription Type',
                'Payment Mode',
                'Bank Reference',
                'Transaction Date',
                'Transaction Amount',
                'Payment Status',
            ];

            rows.forEach((row) => {
                // Check if the row contains any data
                if (row.some((field) => field.trim() !== '')) {
                    const rowData = {};

                    // Assign the first 6 fields with respective headers
                    for (let i = 0; i < 6; i++) {
                        rowData[this.headers[i]] = row[i];
                    }

                    // Process the rest of the row starting from the 7th field up to column 25
                    for (let i = 6; i < 26 && i < row.length; i += 2) {
                        const header = row[i];
                        const value = row[i + 1];
                        if (!this.headers.includes(header)) {
                            this.headers.push(header);
                        }
                        rowData[header] = value;
                    }
                    if (!this.headers.includes('Remarks')) {
                        this.headers.push('Remarks');
                    }
                    rowData['Remarks'] = row[26];

                    dataWithHeaders.push(rowData);
                } else {
                    console.log('Empty row found', row);
                }
            });

            this.parsedData = dataWithHeaders.map((row) => ({
                ...row,
                success: false,
                error: false,
            }));
        },
        toggleEditMode() {
            if (this.selectedRows.length > 0) {
                this.editMode = !this.editMode;
                // Clear selectedRows when editMode is toggled off
                if (!this.editMode) {
                    this.selectedRows = [];
                }
            }
        },
        // Method to update counts and start/reset the timeout
        updateCountsAndStartTimeout() {
            // Log parsedData for debugging
            //console.log('Parsed data:', this.parsedData);

            // Increment counts based on success, error, and conflict flags
            this.accountsCreatedCount = this.parsedData.filter(
                (row) => row.success,
            ).length;
            this.accountsFailedCount = this.parsedData.filter(
                (row) => row.error,
            ).length;
            this.accountsConflictCount = this.parsedData.filter(
                (row) => row.conflict,
            ).length;

            // Log counts for debugging
            //console.log('Accounts created count:', this.accountsCreatedCount);
            //console.log('Accounts failed count:', this.accountsFailedCount);
            //console.log('Accounts conflict count:', this.accountsConflictCount);

            // Show timed row and reset timeout
            this.accountsTimedRow = true;
            clearTimeout(this.timedRowTimeout); // Clear previous timeout if any
            this.timedRowTimeout = setTimeout(() => {
                // Hide timed row after 120 seconds
                this.accountsTimedRow = false;
            }, 120000); // Timeout duration: 120 seconds
        },
        createAccounts() {
            // Array to store promises for all fetch requests
            const fetchPromises = [];

            this.selectedRows.forEach((index) => {
                const rowData = this.parsedData[index];
                const token = localStorage.getItem('token');

                // Check if 'DBUID No.' key exists in rowData
                if ('DBUID No.' in rowData) {
                    const promise = fetch(`${BASE_URL}/api/v1/createAccount`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(rowData),
                    })
                        .then((response) => {
                            if (response.status === 409) {
                                // If status is 409 (CONFLICT)
                                this.parsedData[index].conflict = true;
                                this.parsedData[index].success = false;
                                this.parsedData[index].error = false;
                            } else if (response.ok) {
                                // If status is OK
                                this.parsedData[index].conflict = false;
                                this.parsedData[index].success = true;
                                this.parsedData[index].error = false;
                            } else {
                                // If status is not OK
                                this.parsedData[index].conflict = false;
                                this.parsedData[index].success = false;
                                this.parsedData[index].error = true;
                            }
                        })
                        .catch(() => {
                            this.parsedData[index].conflict = false;
                            this.parsedData[index].success = false;
                            this.parsedData[index].error = true;
                        })
                        .finally(() => {
                            // Remove the index from selectedRows array
                            this.selectedRows.splice(
                                this.selectedRows.indexOf(index),
                                1,
                            );

                            // Disable the row's selectable state
                            this.selectableRows[index] = false;

                            // Disable the "Select All" checkbox in the header when all checkboxes are disabled
                            if (this.areAllCheckboxesDisabled()) {
                                this.selectAll = false;
                                return;
                            }
                        });

                    fetchPromises.push(promise);
                } else {
                    console.error("'DBUID No.' key is missing in rowData.");
                }
            });
            // Wait for all fetch requests to complete
            Promise.all(fetchPromises)
                .then(() => {
                    // All fetch requests completed successfully
                    // Update counts and start/reset timeout
                    this.updateCountsAndStartTimeout();
                })
                .catch((error) => {
                    // Error occurred during one or more fetch requests
                    console.error('Error during fetch requests:', error);
                });
        },
    },
};
</script>

<style scoped>
/* Custom table styles */
.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

/* Adjust column widths */
.custom-table th:first-child,
.custom-table td:first-child {
    width: 150px; /* Adjust the width as needed */
}

/* Enable text wrapping */
.custom-table td {
    white-space: normal;
}

a {
    color: #42b983;
}
.success-row {
    background-color: #5cb85c; /* Green background */
    color: #fff; /* White text color */
}
.error-row {
    background-color: #d9534f; /* Red background */
    color: #fff; /* White text color */
}
.conflict-row {
    background-color: yellow; /* Yellow background */
    color: black; /* Black text color */
}
</style>

// store/auth.js
export const state = () => ({
    isAuthenticated: false,
});

export const mutations = {
    setAuthenticationStatus(state, status) {
        state.isAuthenticated = status;
    },
};

export const actions = {
    login({ commit }) {
        // Perform login logic here
        // After successful login, commit the mutation to set isAuthenticated to true
        commit('setAuthenticationStatus', true);
    },
    logout({ commit }) {
        // Perform logout logic here
        // After logout, commit the mutation to set isAuthenticated to false
        commit('setAuthenticationStatus', false);
    },
};

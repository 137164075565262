<template>
  <div>
    <h2>Logout</h2>
    <p>Are you sure you want to logout?</p>
    <button @click="logout">
      Logout
    </button>
  </div>
</template>

<script>
import { BASE_URL, API_KEY } from '../api';
// eslint-disable-next-line no-unused-vars
import { logout } from '../store/auth';

export default {
    methods: {
        async logout() {
            try {
                const token = localStorage.getItem('token');
                // Send logout request to server
                const response = await fetch(`${BASE_URL}/api/v1/logout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'API-Key': API_KEY,
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include', // Include cookies in the request
                });
                if (response.ok) {
                    // Successful logout, dispatch logout action to update authentication status
                    this.$store.dispatch('logout');
                    // Successful logout, redirect to login page
                    this.$router.push('/');
                } else {
                    // Handle error response
                    console.error('Logout failed:', response.statusText);
                    // Redirect to login page even on failure for security reasons
                    this.$router.push('/');
                }
            } catch (error) {
                console.error('Error:', error);
                // Redirect to login page on error for security reasons
                this.$router.push('/');
            }
        },
    },
};
</script>
